// The next line initialize a constant who contain the url to the wanted environment
const backend = "https://s6xqiwnm0m.execute-api.us-east-1.amazonaws.com/dev/";
// The next line initialize an object who contain the configs to access the AWS Cognito
const awsConfig = {
    cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_47N5U4pul",
        APP_CLIENT_ID: "lmq362ckor2pke03p9gn5d055",
        IDENTITY_POOL_ID: "us-east-1:db1a3fc5-c558-4f1f-ac12-e72ffcdc441d",
    },
};

const getCognitoConfigs = () => {
    return {
        mandatorySignIn: true,
        region: awsConfig.cognito.REGION,
        userPoolId: awsConfig.cognito.USER_POOL_ID,
        identityPoolId: awsConfig.cognito.IDENTITY_POOL_ID,
        userPoolWebClientId: awsConfig.cognito.APP_CLIENT_ID,
    };
};

export default {
    backend,
    getCognitoConfigs,
};
